<template>
    <v-dialog :persistent="deleting === true" v-model="deleteInstanceDialog" max-width="700">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip left>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn :disabled="disabledButton" small color="error" icon v-on="{ ...tooltip, ...dialog }">
                        <v-icon small>delete</v-icon>
                    </v-btn>
                </template>
                <span>Delete this instance</span>
            </v-tooltip>
        </template>
        <v-card flat>
            <v-card-title>
                <div class="d-flex align-center primary--text">
                    <v-icon class="mr-1">delete</v-icon>
                    <span class="text-uppercase">instance deletion</span>
                </div></v-card-title
            >
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-card-text>
                <v-row no-gutters align="center">
                    <v-col cols="4">
                        <v-subheader>INSTANCE NAME</v-subheader>
                    </v-col>
                    <v-col cols="8" class="subtitle-2">{{ instanceName }}</v-col>
                </v-row>
                <v-row no-gutters align="center">
                    <v-col cols="4">
                        <v-subheader>INSTANCE DESCRIPTION</v-subheader>
                    </v-col>
                    <v-col cols="8" class="subtitle-2">{{ instanceDescription }}</v-col>
                </v-row>
                <v-alert outlined color="error" prominent type="warning" class="mt-5">
                    <span class="font-weight-bold">Here is what happens when you delete an instance</span>
                    <ul class="mt-3">
                        <li>
                            <span class="subtitle-2">
                                The contents (files, tables, and application) of all states in the instance will be deleted permanently.
                            </span>
                        </li>
                        <li class="subtitle-2">
                            All members of this instance will lose their access and data.
                        </li>
                    </ul>
                    <v-divider class="my-4 primary" style="opacity: 0.22"></v-divider>
                    <v-checkbox class="primary--text" color="error" v-model="agreeBox" label="I understand"></v-checkbox>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <div class="d-flex flex-column">
                    <div class="d-flex align-center justify-end">
                        <v-btn :disabled="deleting" color="primary" text @click="deleteInstanceDialog = false">cancel</v-btn>
                        <DeleteButton
                            buttonName="delete"
                            objectType="instance"
                            :buttonInsideDialog="true"
                            :apiURL="'/instances/' + instanceId"
                            :id="parseInt(instanceId, 10)"
                            :higherLevelId="parseInt($route.params.sid, 10)"
                            getterString="instanceStore/instanceLongNameById"
                            warningText="You are about to delete this instance with all its contents permanently. This means that all your data and the snapshots related to this instance will be removed."
                            @error="errorMessage($event.error)"
                            @deleting="setDeletingStatus($event.value)"
                            :disabled="!agreeBox"
                            fetchString="spaceStore/fetchSpaceInstances"
                            :routeAfter="
                                instanceId.toString() === $route.params.iid.toString()
                                    ? {
                                          name: 'snapshot-overview',
                                          params: { oid: $route.params.oid, sid: $route.params.sid, iid: masterInstanceId, snid: masterDevelopmentSnapshotId }
                                      }
                                    : null
                            "
                        ></DeleteButton>
                    </div>
                    <div>
                        <v-alert v-if="error" color="error" icon="warning" class="mt-4" outlined>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">{{ errorContent }}</span>
                                <span>
                                    For more information on instance deletion issues, check the troubleshooting documentation
                                    <v-btn
                                        class="font-weight-bold"
                                        small
                                        text
                                        color="error"
                                        href="https://docs.nuvolos.cloud/troubleshooting/authorization-issues/cannot-delete-an-instance"
                                        target="_blank"
                                        >here</v-btn
                                    >
                                </span>
                            </div>
                        </v-alert>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { enumsData } from '@/mixins/enums'
const DeleteButton = () => import('@/components/DeleteButton')

export default {
    name: 'InstanceDeleteDialog',
    mixins: [enumsData],
    components: {
        DeleteButton
    },
    props: {
        instanceId: Number,
        instanceName: String,
        instanceDescription: String,
        masterInstanceId: String,
        masterDevelopmentSnapshotId: String
    },
    data() {
        return {
            error: false,
            errorContent: 'An error has occurred',
            loading: false,
            agreeBox: false,
            deleting: false,
            deleteInstanceDialog: false
        }
    },
    computed: {
        disabledButton() {
            if (
                this.$props.instanceName &&
                [this.instanceFixedNames.MASTER_INSTANCE, this.instanceFixedNames.DISTRIBUTED_INSTANCE].includes(this.$props.instanceName) === true
            ) {
                return true
            }
            return false
        }
    },
    methods: {
        errorMessage: function(error) {
            if (error) {
                this.$data.error = true
                this.$data.errorContent = 'An error has occurred'
            }
        },
        setDeletingStatus: function(deletingStatus) {
            if (deletingStatus) {
                this.$data.deleting = deletingStatus
            }
        }
    }
}
</script>
